export const uploadToGoogleDrive = async (pdfBlob, fileName) => {
   // const apiUrl = "http://localhost:8888/.netlify/functions";
   const apiUrl = "https://nearshore-backend.netlify.app/.netlify/functions";
   try {
      // Convert the Blob to Base64
      const base64File = await new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onloadend = () => resolve(reader.result.split(",")[1]); // Get the Base64 content
         reader.onerror = reject;
         reader.readAsDataURL(pdfBlob);
      });

      console.log(JSON.stringify({ fileName, fileContent: base64File }));

      // Make a POST request to the backend function
      const response = await fetch(`${apiUrl}/uploadToDrive`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ fileName, fileContent: base64File }),
      });

      // Handle the response
      if (!response.ok) throw new Error("Upload failed");

      const data = await response.json();
      return {
         fileId: data.fileId,
         link: data.webViewLink,
      };
   } catch (error) {
      console.error("Error uploading file:", error);
   }
};
