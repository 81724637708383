import React from "react";
import styled from "styled-components";
import InfoSVG from "../../images/symbols/info.svg";

const TooltipContainer = styled.div`
   display: inline-block;
   position: relative;
   cursor: pointer;
   margin-left: 10px;
`;

const TooltipContent = styled.div`
   visibility: hidden;
   background-color: #000000cc;
   color: var(--text-inverted);
   padding: 5px 10px;
   border-radius: 4px;
   position: absolute;
   bottom: 125%; /* Position above the icon */
   left: 50%;
   transform: translateX(-50%);
   width: max-content;
   max-width: 300px;
   font-size: 12px;
   transition: opacity 0.3s ease;
   font-weight: 500;

   ul {
      margin: 0;
      padding-left: 20px;
   }
`;

const TooltipIcon = styled.div`
   display: inline-block;

   svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
   }

   &:hover + ${TooltipContent}, ${TooltipContainer}:hover ${TooltipContent} {
      visibility: visible;
      opacity: 1;
   }
`;

export default function Tooltip({ isSeniority }) {
   return (
      <TooltipContainer>
         <TooltipIcon>
            <InfoSVG />
         </TooltipIcon>
         <TooltipContent>
            {isSeniority ? (
               <>
                  Seniority:
                  <ul>
                     <li>Junior: up to 2 years of experience</li>
                     <li>Mid-level: 3 to 5 years of experience</li>
                     <li>Senior: over 5 years of experience</li>
                  </ul>
               </>
            ) : (
               <>
                  The number of hours an employee is allocated to your project
                  in a month, expressed as a percentage of full-time hours (max
                  40h/week).
               </>
            )}
         </TooltipContent>
      </TooltipContainer>
   );
}
