export const Roles = {
   JavaDev: "javaDev",
   AngularDev: "angularDev",
   PythonDev: "pythonDev",
   ReactDev: "reactDev",
   DotnetDev: "dotnetDev",
   Android: "android",
   Ios: "ios",
   UxUi: "uxui",
   Cloud: "cloud",
   Infrastructure: "infrastructure",
   Security: "security",
   Qa: "qa",
   MachineLearning: "machineLearning",
   ProjectManager: "projectManager",
   ScrumMaster: "scrumMaster",
   ProductOwner: "productOwner",
   ProductManager: "productManager",
};

const RolesCategories = {
   WebDevelopment: "webdev",
   ItManagment: "it",
   UxUi: "uxui",
   Cloud: "cloud",
   Mobile: "mobile",
   Software: "software",
   QA: "qa",
   MachineLearning: "ml",
};

export const Seniorities = {
   Junior: "junior",
   MidLevel: "mid-level",
   Senior: "senior",
};

export const WorkTimes = {
   Twenty: "20%",
   Forthy: "40%",
   Sixty: "60%",
   Eighty: "80%",
   OneHundred: "100%",
};

export const Services = {
   NewTeam: "newTeam",
   UpgradeTeam: "upgradeTeam",
};

export const RolesOptions = [
   {
      value: Roles.JavaDev,
      label: "Java Developer",
      category: RolesCategories.Software,
      rate: 26,
   },
   {
      value: Roles.DotnetDev,
      label: "Dotnet Developer",
      category: RolesCategories.Software,
      rate: 26,
   },
   {
      value: Roles.PythonDev,
      label: "Python Developer",
      category: RolesCategories.Software,
      rate: 29,
   },
   {
      value: Roles.AngularDev,
      label: "Angular Developer",
      category: RolesCategories.WebDevelopment,
      rate: 26,
   },
   // {
   //    value: Roles.ReactDev,
   //    label: "React Developer",
   //    category: RolesCategories.WebDevelopment,
   //    rate: 26,
   // },
   // {
   //    value: Roles.Android,
   //    label: "Android Developer",
   //    category: RolesCategories.Mobile,
   //    rate: 28,
   // },
   // {
   //    value: Roles.Ios,
   //    label: "iOS Developer",
   //    category: RolesCategories.Mobile,
   //    rate: 28,
   // },
   {
      value: Roles.UxUi,
      label: "UX/UI Designer",
      category: RolesCategories.UxUi,
      rate: 24,
   },
   {
      value: Roles.Cloud,
      label: "Cloud engineer",
      category: RolesCategories.Cloud,
      rate: 31,
   },
   // {
   //    value: Roles.Infrastructure,
   //    label: "Infrastructure engineer / Devops",
   //    category: RolesCategories.Cloud,
   //    rate: 31,
   // },
   // {
   //    value: Roles.Security,
   //    label: "Security engineer",
   //    category: RolesCategories.Cloud,
   //    rate: 31,
   // },
   // {
   //    value: Roles.Qa,
   //    label: "QA tester",
   //    category: RolesCategories.Qa,
   //    rate: 24,
   // },
   {
      value: Roles.MachineLearning,
      label: "Machine learning",
      category: RolesCategories.MachineLearning,
      rate: 28,
   },
   // {
   //    value: Roles.ProjectManager,
   //    label: "Project Manager",
   //    category: RolesCategories.ItManagment,
   //    rate: 29,
   // },
   // {
   //    value: Roles.ScrumMaster,
   //    label: "Scrum Master",
   //    category: RolesCategories.ItManagment,
   //    rate: 29,
   // },
   // {
   //    value: Roles.ProductOwner,
   //    label: "Product Owner",
   //    category: RolesCategories.ItManagment,
   //    rate: 29,
   // },
   // {
   //    value: Roles.ProductManager,
   //    label: "Product Manager",
   //    category: RolesCategories.ItManagment,
   //    rate: 29,
   // },
];

export const SenioritiesOptions = [
   {
      value: Seniorities.Junior,
      label: "Junior",
      seniorityRate: 0,
   },
   {
      value: Seniorities.MidLevel,
      label: "Mid-level",
      seniorityRate: 0.2,
   },
   {
      value: Seniorities.Senior,
      label: "Senior",
      seniorityRate: 0.45,
   },
];

export const WorkTimesOptions = [
   { value: WorkTimes.Twenty, label: "8h" },
   { value: WorkTimes.Forthy, label: "16h" },
   { value: WorkTimes.Sixty, label: "24h" },
   { value: WorkTimes.Eighty, label: "32h" },
   { value: WorkTimes.OneHundred, label: "40h" },
];

export const ServicesOptions = [
   { value: Services.NewTeam, label: "Form a new team" },
   { value: Services.UpgradeTeam, label: "Upgrade my team" },
];

const AssemblyTimesGroups = [
   {
      category: RolesCategories.WebDevelopment,
      time: [
         { seniority: Seniorities.Junior, value: 8 },
         { seniority: Seniorities.MidLevel, value: 9 },
         { seniority: Seniorities.Senior, value: 10 },
      ],
   },
   {
      category: RolesCategories.Software,
      time: [
         { seniority: Seniorities.Junior, value: 8 },
         { seniority: Seniorities.MidLevel, value: 9 },
         { seniority: Seniorities.Senior, value: 10 },
      ],
   },
   {
      category: RolesCategories.ItManagment,
      time: [
         { seniority: Seniorities.Junior, value: 8 },
         { seniority: Seniorities.MidLevel, value: 8 },
         { seniority: Seniorities.Senior, value: 8 },
      ],
   },
   {
      category: RolesCategories.UxUi,
      time: [
         { seniority: Seniorities.Junior, value: 7 },
         { seniority: Seniorities.MidLevel, value: 7 },
         { seniority: Seniorities.Senior, value: 8 },
      ],
   },
   {
      category: RolesCategories.Cloud,
      time: [
         { seniority: Seniorities.Junior, value: 10 },
         { seniority: Seniorities.MidLevel, value: 13 },
         { seniority: Seniorities.Senior, value: 15 },
      ],
   },
   {
      category: RolesCategories.Mobile,
      time: [
         { seniority: Seniorities.Junior, value: 8 },
         { seniority: Seniorities.MidLevel, value: 9 },
         { seniority: Seniorities.Senior, value: 10 },
      ],
   },
   {
      category: RolesCategories.QA,
      time: [
         { seniority: Seniorities.Junior, value: 8 },
         { seniority: Seniorities.MidLevel, value: 8 },
         { seniority: Seniorities.Senior, value: 9 },
      ],
   },
   {
      category: RolesCategories.MachineLearning,
      time: [
         { seniority: Seniorities.Junior, value: 8 },
         { seniority: Seniorities.MidLevel, value: 8 },
         { seniority: Seniorities.Senior, value: 9 },
      ],
   },
];

export const formatCost = (cost) => {
   if (!cost) return "€ 0.00";
   return `€ ${cost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const calculateRoleCost = (resource, billableHours) => {
   const rate = RolesOptions.find((entry) => entry.value === resource.role);

   const seniorityRate = SenioritiesOptions.find(
      (s) => s.value === resource.seniority
   )?.seniorityRate;

   const ratePerHour = rate + rate * seniorityRate;
   const worktimePercentage = parseFloat(resource.worktime) / 100;
   const totalCost =
      ratePerHour * billableHours * worktimePercentage * resource.count;

   return totalCost;
};

const formatTime = (totalDays) => {
   if (totalDays < 7) {
      return `${totalDays} day${totalDays !== 1 ? "s" : ""}`;
   } else if (totalDays < 30) {
      const weeks = Math.floor(totalDays / 7);
      const remainingDays = totalDays % 7;
      let result = `${weeks} week${weeks > 1 ? "s" : ""}`;

      if (remainingDays > 0) {
         result += ` and ${remainingDays} day${remainingDays !== 1 ? "s" : ""}`;
      }

      return result;
   } else {
      const months = Math.floor(totalDays / 30);
      const remainingDays = totalDays % 30;
      let result = `${months} month${months > 1 ? "s" : ""}`;

      if (remainingDays > 0) {
         if (remainingDays >= 7) {
            const weeks = Math.floor(remainingDays / 7);
            result += ` and ${weeks} week${weeks > 1 ? "s" : ""}`;
         } else {
            result += ` and ${remainingDays} day${
               remainingDays !== 1 ? "s" : ""
            }`;
         }
      }

      return result;
   }
};

const fetchHolidays = async (startYear, endYear, country) => {
   const allHolidays = [];

   for (let year = startYear; year <= endYear; year++) {
      const response = await fetch(
         `https://date.nager.at/api/v3/publicholidays/${year}/${country}/`
      );
      const data = await response.json();
      const holidays = data.map((holiday) => new Date(holiday.date));
      allHolidays.push(...holidays);
   }
   return allHolidays;
};

const calculateAdditionalDaysOff = (startDate, endDate) => {
   // Calculate total months in the project duration
   // For every 6 months add 10 days, and every year 22 days
   const totalMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());

   // Calculate the number of full years in the project duration
   const fullYears = Math.floor(totalMonths / 12);

   // Calculate additional days off for full years (22 days per year)
   const daysOffForFullYears = fullYears * 22;

   // Calculate the remaining months after full years
   const remainingMonths = totalMonths % 12;

   // Determine additional days off for the remaining months
   let daysOffForRemainingMonths = 0;
   if (remainingMonths >= 6) {
      daysOffForRemainingMonths = 10;
   }

   // Return total additional days off
   return daysOffForFullYears + daysOffForRemainingMonths;
};

const calculateWorkingDays = async (
   startDate,
   endDate = null,
   requiredWorkingDays = 0,
   country = "PT"
) => {
   const startYear = startDate.getFullYear();
   const endYear = endDate ? endDate.getFullYear() : startYear + 1; // Estimate range if endDate not provided

   // Fetch holidays for the relevant years
   const holidays = await fetchHolidays(startYear, endYear, country);

   let workingDays = 0;
   let currentDate = new Date(startDate);

   while (
      endDate ? currentDate <= endDate : workingDays < requiredWorkingDays
   ) {
      const dayOfWeek = currentDate.getDay();
      const isHoliday = holidays.some(
         (holiday) => holiday.getTime() === currentDate.getTime()
      );

      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isHoliday) {
         workingDays++;
      }

      // If calculating end date for required working days, return it
      if (!endDate && workingDays === requiredWorkingDays) {
         return currentDate;
      }

      currentDate.setDate(currentDate.getDate() + 1);
   }

   // If calculating total working days in a range
   if (endDate) {
      const additionalDaysOff = calculateAdditionalDaysOff(startDate, endDate);
      return workingDays - additionalDaysOff;
   }
};

const calculateProjectDuration = (startDate, endDate) => {
   const start = new Date(startDate);
   const end = new Date(endDate);

   // Calculate the difference in months
   const months =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

   // Add 1 to include both start and end months
   return months + 1;
};

export const estimateAssemblyTime = async (resources) => {
   let totalWorkingDays = 0;

   resources.forEach((resource) => {
      const roleGroup = RolesOptions.find(
         (role) => role.value === resource.role
      )?.category;
      const assemblyTimes = AssemblyTimesGroups.find(
         (group) => group.category === roleGroup
      );

      const timeBySeniority = assemblyTimes.time.find(
         (time) => time.seniority === resource.seniority
      )?.value;

      const timeNeeded = timeBySeniority * resource.count;
      totalWorkingDays += timeNeeded;
   });

   // Start counting from tomorrow
   const today = new Date();
   const startDate = new Date(today);
   startDate.setDate(today.getDate() + 1);

   // Calculate the projected end date based on required working days
   const projectedEndDate = await calculateWorkingDays(
      startDate,
      null,
      totalWorkingDays
   );

   // Format the difference in days
   const daysTaken = Math.ceil(
      (projectedEndDate - new Date()) / (1000 * 60 * 60 * 24)
   );

   return formatTime(daysTaken);
};

export const calculateResults = async (startDate, endDate, resources) => {
   const billableHoursPerDay = 8;
   const workingDays = await calculateWorkingDays(
      new Date(startDate),
      new Date(endDate)
   );
   const totalBillableHours = workingDays * billableHoursPerDay;

   const updatedResources = resources.map((resource) => {
      const worktimePercentage = parseFloat(resource.worktime) / 100;
      const roleRate = RolesOptions.find(
         (role) => role.value === resource.role
      );
      const hourlyRate = roleRate?.rate || 0;
      const hours = Math.ceil(totalBillableHours * worktimePercentage);

      const timePremium = Math.ceil(
         calculateProjectDuration(startDate, endDate) < 6 ? hourlyRate * 0.3 : 0
      );
      const seniorityRate = Math.ceil(
         SenioritiesOptions.find((s) => s.value === resource.seniority)
            ?.seniorityRate * hourlyRate
      );
      const effectiveHourlyRate = hourlyRate + seniorityRate + timePremium;
      const totalPriceForEachRole =
         hours * effectiveHourlyRate * resource.count;

      return {
         ...resource,
         hours,
         hourlyRate,
         totalPrice: totalPriceForEachRole,
         seniorityRate,
         timePremium,
      };
   });

   // Estimate the time to assemble the team
   const deliveryTime = await estimateAssemblyTime(resources);

   const totalCost = updatedResources.reduce(
      (sum, resource) => sum + resource.totalPrice,
      0
   );
   const billableHours = updatedResources.reduce(
      (sum, resource) => sum + resource.hours * resource.count,
      0
   );

   return {
      resources: updatedResources,
      deliveryTime,
      billableHours,
      totalCost,
   };
};
