import React from "react";
import RadioInput from "../atoms/radioInput";
import InputComponent from "../atoms/input";
import SearchSVG from "../../images/symbols/search.svg";
import TrashSVG from "../../images/symbols/trash.svg";
import DatePickerInput from "../atoms/datePicker";
import styled from "styled-components";
import {
   RolesOptions,
   SenioritiesOptions,
   WorkTimesOptions,
   Services,
} from "../../helpers/calculator";
import SelectBox from "../atoms/selectBox";
import uniqueId from "lodash/uniqueId";
import Tooltip from "./tooltip";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 40px;
   justify-content: space-between;
   flex-grow: 1;

   .skillsContainer {
      display: flex;
      gap: 16px;
   }

   .skillContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   .skillContainer .roleHeading {
      font-weight: 700;
   }

   .skillContainer.role,
   .skillContainer.seniority {
      flex-grow: 1;
      flex-basis: 0;
   }

   .skillContainer.count {
      width: 120px;
   }

   .roleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .roleContainer svg {
      cursor: pointer;
   }

   .noResources {
      display: flex;
      width: 100%;
      height: 44px;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: var(--medium-bg);
      border-radius: 8px;
      color: var(--text-muted);
   }

   table {
      width: 100%;
      max-width: 100%;
      border: 1px solid #c8c8d0;
      border-radius: 6px;
      border-spacing: unset;
   }

   th {
      text-align: start;
      color: var(--text-muted);
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      padding: 7.5px 16px;
      background-color: var(--medium-bg);
      border-top: none;
      border-left: none;
   }

   th:first-child {
      border-top-left-radius: 6px;
   }

   th:last-child {
      border-top-right-radius: 6px;
   }

   td:first-child,
   th:first-child {
      border-left: none;
   }

   table td + td,
   table th + th {
      border-left: 1px solid #c8c8d0;
   }

   td {
      color: var(--text-primary);
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      border-top: 1px solid #c8c8d0;
      padding: 10px 16px;
      border-left: none;
   }

   .inputWrapper .errorMessage {
      color: #d32f2f;
      font-size: 12px;
      font-weight: 400;
   }
`;

export default function CalculatorContent({
   formProps,
   isMobile,
   handleStartDateChange,
   handleEndDateChange,
   errors,
}) {
   const {
      serviceOption,
      setServiceOption,
      startDate,
      endDate,
      description,
      setDescription,
      resources = [],
      setResources,
   } = formProps;

   const addResource = (role) => {
      const newResource = {
         id: uniqueId("role_"),
         role: role.value,
         seniority: null,
         worktime: null,
         count: null,
      };
      setResources([...resources, newResource]);
   };
   const deleteResource = (roleId) => {
      const newResources = resources.filter((s) => s.id !== roleId);
      setResources(newResources);
   };
   return (
      <Container>
         <div className="formContent">
            <div className="inputSection">
               <p>What service are you looking for?</p>
               <div className="inputContainer">
                  <RadioInput
                     label="Form a new team"
                     name="serviceOption"
                     value={Services.NewTeam}
                     checked={serviceOption === Services.NewTeam}
                     onChange={() => setServiceOption(Services.NewTeam)}
                  />
                  <RadioInput
                     label="Upgrade my team"
                     name="serviceOption"
                     value={Services.UpgradeTeam}
                     checked={serviceOption === Services.UpgradeTeam}
                     onChange={() => setServiceOption(Services.UpgradeTeam)}
                  />
               </div>
            </div>
            <div className="inputSection">
               <p>Add skills</p>
               <div className="inputContainer">
                  <SelectBox
                     Icon={SearchSVG}
                     placeholder="Search skills..."
                     options={RolesOptions}
                     onChange={(e) => addResource(e)}
                     clearOnSelect
                  />
               </div>
            </div>
            {resources?.length > 0 && !isMobile && (
               <table className="skillsTable">
                  <thead>
                     <tr>
                        <th style={{ width: "30%" }}>Role</th>
                        <th style={{ width: "25%" }}>
                           Seniority <Tooltip isSeniority />
                        </th>
                        <th style={{ width: "25%" }}>
                           Worktime <Tooltip />
                        </th>
                        <th style={{ width: "20%" }}>Count</th>
                     </tr>
                  </thead>
                  <tbody>
                     {resources.map((resource) => (
                        <tr key={resource.id}>
                           <td className="roleContainer">
                              <p>
                                 {
                                    RolesOptions.find(
                                       (option) =>
                                          option.value === resource?.role
                                    )?.label
                                 }
                              </p>
                              <TrashSVG
                                 onClick={() => deleteResource(resource.id)}
                              />
                           </td>
                           <td className="selectContainer">
                              <SelectBox
                                 options={SenioritiesOptions}
                                 value={SenioritiesOptions.find(
                                    (option) =>
                                       option.value === resource?.seniority
                                 )}
                                 onChange={(e) => {
                                    const updatedResources = resources.map(
                                       (s) =>
                                          s.id === resource.id
                                             ? { ...s, seniority: e.value }
                                             : s
                                    );
                                    setResources(updatedResources);
                                 }}
                                 isInsideTable
                              />
                           </td>
                           <td className="selectContainer">
                              <SelectBox
                                 options={WorkTimesOptions}
                                 value={WorkTimesOptions.find(
                                    (option) =>
                                       option.value === resource?.worktime
                                 )}
                                 isInsideTable
                                 onChange={(e) => {
                                    const updatedResources = resources.map(
                                       (s) =>
                                          s.id === resource.id
                                             ? { ...s, worktime: e.value }
                                             : s
                                    );
                                    setResources(updatedResources);
                                 }}
                              />
                           </td>
                           <td className="count">
                              <InputComponent
                                 type="number"
                                 variant="form"
                                 value={resource.count}
                                 placeholder="Insert..."
                                 handleChangeValue={(e) => {
                                    const updatedResources = resources.map(
                                       (s) =>
                                          s.id === resource.id
                                             ? { ...s, count: e.target.value }
                                             : s
                                    );
                                    setResources(updatedResources);
                                 }}
                                 isInsideTable
                                 min={1}
                              />
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            )}
            <div className="inputSection">
               <p>Project time frame</p>
               <div className="inputContainer">
                  <div className="inputWrapper">
                     <DatePickerInput
                        placeholder="Pick start date..."
                        value={startDate}
                        onChange={handleStartDateChange}
                        minDate={
                           new Date(
                              new Date().setDate(new Date().getDate() + 14)
                           )
                        } // 2 weeks from today
                     />
                     {errors.startDate && (
                        <p className="errorMessage">{errors.startDate}</p>
                     )}
                  </div>
                  <div className="inputWrapper">
                     <DatePickerInput
                        placeholder="Pick end date..."
                        value={endDate}
                        onChange={handleEndDateChange}
                        minDate={
                           new Date(
                              startDate
                                 ? new Date(startDate).setMonth(
                                      new Date(startDate).getMonth() + 1
                                   )
                                 : new Date()
                           )
                        } // At least 1 month after startDate
                     />
                     {errors.endDate && (
                        <p className="errorMessage">{errors.endDate}</p>
                     )}
                  </div>
               </div>
            </div>
            <div className="inputSection">
               <p>Describe your project briefly (optional)</p>
               <div className="inputContainer">
                  <InputComponent
                     isTextArea
                     variant="form"
                     value={description}
                     placeholder="Insert..."
                     handleChangeValue={(e) => setDescription(e.target.value)}
                     height="160px"
                  />
               </div>
            </div>
         </div>
      </Container>
   );
}
