import React from "react";
import styled from "styled-components";

const RadioContainer = styled.div`
   display: flex;
   align-items: center;
   flex-grow: 1;
   gap: 10px;
   padding: 10px 12px;
   border: ${({ checked }) =>
      checked ? "1px solid var(--accent-primary)" : "1px solid #C8C8D0"};
   border-radius: 8px;
   background-color: ${({ checked }) =>
      checked ? "var(--accent-primary-05)" : "transparent"};
   font-size: 16px;
   cursor: pointer;

   input[type="radio"] {
      display: none;
   }

   .checkmark-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
   }

   .custom-radio-checkmark {
      height: 20px;
      width: 20px;
      outline: ${({ checked }) =>
         checked ? "2px solid var(--accent-primary)" : "2px solid #C8C8D0"};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .custom-radio-checkmark::after {
      content: "";
      height: 14px;
      width: 14px;
      background-color: var(--accent-primary);
      border-radius: 50%;
      display: ${({ checked }) => (checked ? "block" : "none")};
   }

   .custom-radio-container
      input[type="radio"]:checked
      + .custom-radio-checkmark::after {
      display: block;
   }

   .label {
      color: var(--text-primary);
      font-weight: 500;
   }
`;

const RadioInput = ({ label, name, value, checked, onChange }) => {
   return (
      <RadioContainer checked={checked} onClick={() => onChange(value)}>
         <input
            type="radio"
            id={value}
            value={value}
            name={name}
            checked={checked}
            onChange={() => {}}
         />
         <div className="checkmark-wrapper">
            <span className="custom-radio-checkmark"></span>
         </div>
         <span className="label">{label}</span>
      </RadioContainer>
   );
};

export default RadioInput;
