import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SelectBox from "../components/atoms/selectBox";
import DownloadArrowSVG from "../images/symbols/fileArrowDown.svg";
import DownloadSVG from "../images/symbols/download.svg";
import HandWavingSVG from "../images/symbols/handWaving.svg";
import LinkSVG from "../images/symbols/link.svg";
import CheckCircleSVG from "../images/symbols/checkCircle.svg";
import LinkedinSVG from "../images/symbols/linkedinOutline.svg";
import UserSVG from "../images/symbols/user.svg";
import EmailSVG from "../images/symbols/envelope.svg";
import CompanySVG from "../images/symbols/company.svg";
import FlagSVG from "../images/symbols/flag.svg";
import InputComponent from "./atoms/input";
import Button, { ButtonTypes, ButtonVariants } from "./atoms/button";
import {
   FormTypes,
   submitHubSpotForm,
   validateEmail,
} from "../helpers/hubspotHelper";
import { LinkedinShareButton } from "react-share";
import ShareDropdown from "./atoms/dropdown";
import {
   formatCost,
   RolesOptions,
   SenioritiesOptions,
   ServicesOptions,
} from "../helpers/calculator";
import DownloadInvoice from "../templates/invoicePDF";
import { CountryOptions } from "../enums/countries";
import { uploadToGoogleDrive } from "../helpers/googleDrive";
import { sendHireWiseEmail } from "../helpers/emailClient";

const Tooltip = styled.div`
   position: absolute;
   bottom: 60px;
   left: 50%;
   transform: translateX(-50%);
   background-color: var(--text-muted);
   color: var(--text-inverted);
   padding: 4px 9px;
   border-radius: 5px;
   font-size: 12px;
   line-height: 19px;
   opacity: ${({ visible }) => (visible ? 1 : 0)};
   visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
   transition: opacity 0.3s ease, visibility 0.3s ease;
   white-space: nowrap;
   display: flex;
   align-items: center;
   gap: 6px;

   svg {
      width: 12px;
      height: 12px;
   }

   &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: var(--text-muted) transparent transparent transparent;
   }
`;

const PopUpContainer = styled.div`
   display: flex;
   position: fixed;
   z-index: 999;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: #00000080;
   align-items: center;
   justify-content: center;
`;

const PopUpContent = styled.div`
   display: flex;
   flex-direction: column;
   gap: 32px;
   background-color: var(--light-bg);
   width: 560px;
   box-sizing: border-box;
   height: fit-content;
   position: absolute;
   border-radius: 16px;
   padding: 56px;

   .topContainer,
   .middleContainer,
   .bottomContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .middleContainer .countrySelectBox {
      width: 100%;
   }

   .middleContainer .countrySelectBox svg {
      margin-right: 2px;
   }

   .middleContainer svg {
      color: var(--text-muted);
   }

   .iconWrapper {
      color: var(--accent-primary);
   }

   .iconWrapper,
   .socialLinkIcon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--accent-primary-05);
      width: 44px;
      height: 44px;
      border-radius: 6px;
   }

   .socialLinkIcon:hover {
      background-color: #e0e0eb;
   }

   .topContainer,
   .bottomContainer {
      gap: 20px;
   }
   .topContainer p {
      margin-top: 4px;
      color: var(--text-muted);
      text-align: center;
   }
   .middleContainer {
      gap: 16px;
   }

   .socialLinksContainer {
      display: flex;
      gap: 12px;
   }

   .bottomContainer span {
      color: var(--accent-primary);
      text-decoration: underline;
      cursor: pointer;
   }

   .bottomContainer p {
      color: var(--text-muted);
   }

   .socialLinkIcon {
      background-color: var(--medium-bg);
      width: 48px;
      height: 48px;
      cursor: pointer;
   }

   .bottomContainer button:first-of-type {
      width: 100%;
   }

   @media only screen and (max-width: 600px) {
      width: 90%;
      padding: 32px 20px;

      .bottomContainer p {
         text-align: center;
      }
   }
`;

const DownloadPopup = ({ isOpen, onClose, projectInfo }) => {
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [company, setCompany] = useState("");
   const [country, setCountry] = useState("");
   const [isInfoValid, setIsInfoValid] = useState(false);
   const [tooltipVisible, setTooltipVisible] = useState(false);
   const [emailSent, setEmailSent] = useState(false);
   const url = typeof window !== "undefined" ? window.location.href : "";
   const shareText = "Cut Nearshore Costs with HireWise – Calculate for free";
   const [pdfBlob, setPdfBlob] = useState(null);
   const previousBlob = useRef(null); // Track the previous blob

   const year = new Date().getFullYear();
   const month = new Date().getMonth() + 1;
   const yr = year.toString().slice(-2);
   const formattedMonth = month.toString().padStart(2, "0");
   const downloadFileName = `Nearshore-${yr}-${formattedMonth}.pdf`;

   const handleClose = () => {
      onClose();
      setEmailSent(false);
   };

   const validateInfo = () => {
      const isNameValid = name.trim().length > 0;
      const isEmailValid = validateEmail(email);
      const isCompanyValid = company.trim().length > 0;
      const isCountryValid = country.trim().length > 0;

      setIsInfoValid(
         isNameValid && isEmailValid && isCompanyValid && isCountryValid
      );
   };

   useEffect(() => {
      validateInfo();
   }, [name, email, company, country]);

   const invoiceData = {
      ...projectInfo,
      name,
      email,
      company,
      country,
      submissionDate: new Date(),
   };

   const handleBlobGenerated = (blob) => {
      if (blob && blob !== previousBlob.current) {
         previousBlob.current = blob;
         setPdfBlob(blob);
      }
   };

   const triggerDownload = () => {
      const downloadLink = document.querySelector(
         `a[download="${downloadFileName}"]`
      );

      if (downloadLink) {
         downloadLink.click();
      }
   };

   const serializeResourcesData = (resources) => {
      return resources
         .map((resource) => {
            const roleOption = RolesOptions.find(
               (option) => option.value === resource.role
            );
            const roleLabel = roleOption ? roleOption.label : resource.role;

            const seniorityOption = SenioritiesOptions.find(
               (option) => option.value === resource.seniority
            );
            const seniorityLabel = seniorityOption
               ? seniorityOption.label
               : resource.seniority;

            return `Role: ${roleLabel}, Seniority: ${seniorityLabel}, Worktime: ${resource.worktime}, Count: ${resource.count}`;
         })
         .join("; ");
   };

   const handleSubmit = async () => {
      if (!pdfBlob) {
         console.error("PDF blob not generated yet");
         return;
      }

      setEmailSent(true);
      triggerDownload();
      sendHireWiseEmail(email, name);

      let link = null;

      try {
         // Attempt to upload to Google Drive
         const uploadResult = await uploadToGoogleDrive(
            pdfBlob,
            `${name}-${company}-${yr}-${formattedMonth}`
         );
         link = uploadResult.link;
      } catch (error) {
         console.error("Error uploading to Google Drive:", error);
      }

      try {
         const formData = {
            email,
            firstname: name,
            company,
            lastname: ServicesOptions.find(
               (s) => s.value === projectInfo.serviceOption
            )?.label,
            message: projectInfo.description,
            start_date: projectInfo.startDate,
            graduation_date: projectInfo.endDate,
            jobtitle: serializeResourcesData(projectInfo.resources),
            annualrevenue: formatCost(projectInfo.total),
            zip: projectInfo.billableHours,
            quote_URL: link,
         };

         const hubSpotSuccess = await submitHubSpotForm(
            formData,
            FormTypes.HIREWISE
         );

         if (!hubSpotSuccess) {
            console.error("Failed to submit form to HubSpot");
         }
      } catch (error) {
         console.error("Error submitting form to HubSpot:", error);
      }
   };

   // Disable scroll on the body when the popup is open
   useEffect(() => {
      if (isOpen) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
      return () => {
         document.body.style.overflow = "auto"; // Cleanup on unmount
      };
   }, [isOpen]);

   // Handle click outside of popup content
   useEffect(() => {
      const handleClickOutside = (event) => {
         const popupContent = document.getElementById("popUpContent");
         if (popupContent && !popupContent.contains(event.target)) {
            handleClose();
         }
      };

      if (isOpen) {
         document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [isOpen, onClose]);

   if (!isOpen) return null;

   const handleCopyLink = () => {
      navigator.clipboard.writeText(url).then(() => {
         setTooltipVisible(true);
         setTimeout(() => setTooltipVisible(false), 2000);
      });
   };

   const handleSendEmail = () => {
      const mailtoLink = `mailto:?subject=Check out HireWise&body=I found this amazing tool to calculate hiring costs: ${window.location.href}`;
      window.location.href = mailtoLink;
   };

   return (
      <PopUpContainer>
         <PopUpContent id="popUpContent">
            <div className="topContainer">
               <div className="iconWrapper">
                  {emailSent ? <HandWavingSVG /> : <DownloadArrowSVG />}
               </div>
               <h4>
                  {emailSent
                     ? "Thank you for using HireWise!"
                     : "Download results for free"}
               </h4>
               <p>
                  {emailSent
                     ? "Consider sharing our calculator in your social media!"
                     : "Thank you for using HireWise! Your PDF is ready for download free of charge."}
               </p>
            </div>
            <div className="middleContainer">
               {emailSent ? (
                  <div className="socialLinksContainer">
                     <div className="socialLinkIcon" onClick={handleCopyLink}>
                        <LinkSVG />
                        <Tooltip visible={tooltipVisible}>
                           <CheckCircleSVG />
                           Link copied to clipboard!
                        </Tooltip>
                     </div>
                     <div className="socialLinkIcon" onClick={handleSendEmail}>
                        <EmailSVG />
                     </div>
                     <LinkedinShareButton url={url} title={shareText}>
                        <div className="socialLinkIcon">
                           <LinkedinSVG />
                        </div>
                     </LinkedinShareButton>
                     <ShareDropdown />
                  </div>
               ) : (
                  <>
                     <InputComponent
                        type="text"
                        value={name}
                        placeholder="Name"
                        handleChangeValue={(e) => setName(e.target.value)}
                        Icon={UserSVG}
                        variant="form"
                     />
                     <InputComponent
                        type="email"
                        value={email}
                        placeholder="Email"
                        handleChangeValue={(e) => setEmail(e.target.value)}
                        Icon={EmailSVG}
                        variant="form"
                     />
                     <InputComponent
                        type="text"
                        variant="form"
                        value={company}
                        placeholder="Company"
                        handleChangeValue={(e) => setCompany(e.target.value)}
                        Icon={CompanySVG}
                     />
                     <SelectBox
                        classname="countrySelectBox"
                        options={CountryOptions}
                        placeholder="Country"
                        value={CountryOptions.find(
                           (option) => option.value === country
                        )}
                        onChange={(e) => setCountry(e.value)}
                        Icon={FlagSVG}
                     />
                  </>
               )}
            </div>
            <div className="bottomContainer">
               {emailSent ? (
                  <>
                     <p>
                        Click <span onClick={triggerDownload}>here</span> if the
                        download doesn’t start in 5 seconds.
                     </p>
                     <Button
                        content="Close"
                        type={ButtonTypes.PRIMARY}
                        onClick={handleClose}
                     />
                  </>
               ) : (
                  <>
                     <Button
                        content="Download PDF"
                        type={ButtonTypes.PRIMARY}
                        disabled={!isInfoValid}
                        onClick={handleSubmit}
                        Icon={DownloadSVG}
                     />
                     <Button
                        content="Not now"
                        type={ButtonTypes.LINK}
                        variant={ButtonVariants.BLACK}
                        onClick={handleClose}
                        style={{
                           textDecoration: "underline",
                           fontWeight: "700",
                        }}
                     />
                  </>
               )}
            </div>
            <DownloadInvoice
               invoiceData={invoiceData}
               style={{ visibility: "hidden", position: "absolute" }}
               onBlobGenerated={handleBlobGenerated}
               downloadFileName={downloadFileName}
            />
         </PopUpContent>
      </PopUpContainer>
   );
};

export default DownloadPopup;
