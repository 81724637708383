import "../components/font-awesome";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import Footer from "../components/homePage/footer";
import CalculatorTemplate from "../templates/calculator";
import HireWiseSEO from "../images/hireWiseSEO.png";

const SeoWrapper = styled.div``;

const CalculatorPage = () => {
   return (
      <SeoWrapper>
         <Seo
            title={"HireWise calculator"}
            description="Cut Nearshore Costs with HireWise – Calculate for free"
            image={HireWiseSEO}
         />
         <Layout>
            <CalculatorTemplate />
            <Footer />
         </Layout>
      </SeoWrapper>
   );
};

export default CalculatorPage;
