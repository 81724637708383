import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
   calculateResults,
   RolesOptions,
   SenioritiesOptions,
   Services,
   WorkTimesOptions,
} from "../helpers/calculator";
import Button, {
   ButtonTypes,
   ButtonVariants,
} from "../components/atoms/button";
import ChartBarSVG from "../images/symbols/chartBar.svg";
import DownloadSVG from "../images/symbols/download.svg";
import BarChart from "../components/calculator/barChart";
import DownloadPopup from "../components/downloadPopup";
import ShareDropdown from "../components/atoms/dropdown";
import TrashSVG from "../images/symbols/trash.svg";
import SelectBox from "../components/atoms/selectBox";
import InputComponent from "../components/atoms/input";
import CalculatorContent from "../components/calculator/calculatorContent";

const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--medium-bg);
`;

const InnerContainer = styled.div`
   display: flex;
   gap: 32px;
   width: 100%;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
   padding: calc(72px + 88px) 0 120px 0;

   @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 36px;
      padding: calc(16px + 88px) 0 120px 0;
   }
`;

const MainWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 16px;
   width: 740px;
   flex-shrink: 0;

   @media only screen and (max-width: 600px) {
      width: unset;
   }
`;

const InfoWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 16px;
   position: sticky;
   top: 120px;
   height: fit-content;
`;

const InfoCard = styled.div`
   display: flex;
   flex-direction: column;
   padding: 32px;
   gap: 16px;
   background-color: #ffffff99;
   border-radius: 16px;
   color: var(--text-primary);

   &.hireWiseHeader {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 40px;
      background-color: var(--light-bg);
   }

   &.hireWiseHeader .leftHeader {
      display: flex;
      flex-direction: column;
   }
   &.hireWiseHeader .rightHeader {
      display: flex;
      gap: 16px;
      align-items: center;
   }

   &.hireWiseHeader p {
      line-height: 20px;
   }

   p {
      color: var(--text-muted);
   }

   &:first-child {
      gap: 0;
   }

   .resultsContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 16px;
   }

   .results {
      display: flex;
      flex-direction: column;
   }
   .resultLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #c8c8d0;
   }
   .resultLine:first-child {
      border-top: 1px solid #c8c8d0;
   }
   .resultLine p {
      width: 50%;
   }
   .resultLine p:last-of-type {
      font-weight: 700;
      text-align: end;
   }

   .resultsButtons {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   .resultsButtons button,
   .resultsButtons a {
      box-sizing: border-box;
      width: 100%;
   }

   &.compareResultsCard {
      padding: 20px;
   }

   .compareResults {
      display: flex;
      flex-direction: column;
   }

   .compareResultsContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
   }

   .barChartWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #c8c8d0;
      border-radius: 6px;
      flex-grow: 1;
      box-sizing: border-box;
      height: 215px;
   }

   canvas {
      padding: 20px 16px 10px 16px;
      max-width: 100%;
      max-height: 100%;
   }

   .countriesCostsContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
   }

   .noResults {
      gap: 16px;
   }

   .noResults .chartBar {
      padding: 16px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--medium-bg);
      border-radius: 6px;
   }

   @media only screen and (max-width: 600px) {
      &.hireWiseHeader {
         padding: 20px 32px;
      }
   }
`;

const CalculatorContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 40px;
   justify-content: space-between;
   flex-grow: 1;
   background-color: var(--light-bg);
   border-radius: 16px;
   padding: 40px 56px;

   .formContent {
      display: flex;
      flex-direction: column;
      gap: 40px;
   }

   .headerContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
   }

   .headerContainer p {
      color: var(--text-muted);
   }

   .inputSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   .inputSection p {
      font-weight: 700;
      color: var(--text-muted);
   }

   .inputContainer {
      display: flex;
      width: 100%;
      gap: 16px;
   }

   .inputWrapper {
      width: 50%;
   }

   .buttonsWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
   }

   .buttonsWrapper button {
      width: 160px;
   }

   .calculator2.buttonsWrapper {
      display: flex;
      justify-content: space-between;
   }

   .backButton {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
   }

   .backButton button {
      color: var(--text-muted);
      width: unset;
   }

   @media only screen and (max-width: 600px) {
      padding: 20px;

      .inputContainer {
         flex-direction: column;
      }
      .inputWrapper {
         width: 100%;
      }
   }
`;

const ResultCountryContainer = styled.div`
   display: flex;
   justify-content: space-between;
   background-color: ${(props) =>
      props.active ? "var(--accent-primary-05)" : "transparent"};
   padding: 8px 16px;
   border-bottom: 1px solid #c8c8d0;

   &:last-child {
      border: none;
   }

   .resultCountry {
      color: var(--text-muted);
      line-height: 28px;
   }
   .resultCountryCost {
      color: ${(props) =>
         props.active ? "var(--accent-primary)" : "var(--text-muted)"};
      font-weight: 700;
      line-height: 28px;
   }
`;

const ResultCountryTag = ({ country, formattedValue, active }) => {
   return (
      <ResultCountryContainer active={active}>
         <p className="resultCountry">{country}:</p>
         <p className="resultCountryCost">{formattedValue}</p>
      </ResultCountryContainer>
   );
};

const MobileResourceCardContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 16px;
   background-color: var(--light-bg);
   padding: 24px 16px;
   border-radius: 16px;

   .inputsWrapper {
      display: flex;
      gap: 12px;
   }

   .inputsWrapper .inputContainer {
      width: 100%;
   }

   .inputContainer {
      display: flex;
      border: 1px solid #c8c8d0;
      padding: 9px 16px;
      border-radius: 8px;
   }

   .roleContainer {
      justify-content: space-between;
      align-items: center;
   }
   .roleContainer p {
      font-weight: 700;
   }
`;

const MobileResourceCard = ({ resource, deleteResource, updateResource }) => {
   return (
      <MobileResourceCardContainer>
         <div className="inputContainer roleContainer">
            <p>
               {
                  RolesOptions.find((option) => option.value === resource?.role)
                     ?.label
               }
            </p>
            <TrashSVG onClick={() => deleteResource(resource.id)} />
         </div>
         <div className="inputContainer selectContainer">
            <SelectBox
               options={SenioritiesOptions}
               value={SenioritiesOptions.find(
                  (option) => option.value === resource?.seniority
               )}
               onChange={(e) =>
                  updateResource(resource.id, "seniority", e.value)
               }
               isInsideTable
               placeholder="Seniority"
            />
         </div>
         <div className="inputsWrapper">
            <div className="inputContainer selectContainer">
               <SelectBox
                  options={WorkTimesOptions}
                  value={WorkTimesOptions.find(
                     (option) => option.value === resource?.worktime
                  )}
                  isInsideTable
                  onChange={(e) =>
                     updateResource(resource.id, "worktime", e.value)
                  }
                  placeholder="Worktime"
               />
            </div>
            <div className="inputContainer countContainer">
               <InputComponent
                  type="number"
                  variant="form"
                  value={resource.count}
                  placeholder="Count"
                  onChange={(e) =>
                     updateResource(resource.id, "count", e.target.value)
                  }
                  isInsideTable
                  min={1}
               />
            </div>
         </div>
      </MobileResourceCardContainer>
   );
};

export default function CalculatorTemplate({ isMobile }) {
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [serviceOption, setServiceOption] = useState(Services.NewTeam);
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [description, setDescription] = useState("");
   const [resources, setResources] = useState([]);
   const [errors, setErrors] = useState({ startDate: "", endDate: "" });

   const [results, setResults] = useState();
   const formProps = {
      serviceOption,
      setServiceOption,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      description,
      setDescription,
      resources,
      setResources,
   };
   const [barChartData, setBarChartData] = useState({
      countries: [],
      costs: [],
   });

   const formatCost = (cost) => {
      if (!cost) return "€ 0.00";
      return `€ ${cost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
   };
   const projectInfo = {
      serviceOption,
      startDate,
      endDate,
      description,
      resources: results?.resources,
      billableHours: results?.billableHours,
      total: results?.totalCost,
   };

   const updateResource = (id, prop, value) => {
      const updatedResources = resources.map((resource) =>
         resource.id === id ? { ...resource, [prop]: value } : resource
      );
      setResources(updatedResources);
   };

   const deleteResource = (roleId) => {
      const newResources = resources.filter((s) => s.id !== roleId);
      setResources(newResources);
   };

   const normalizeDate = (date) => {
      const normalized = new Date(date);
      normalized.setHours(0, 0, 0, 0);
      return normalized;
   };

   const validateDates = (start, end) => {
      if (
         !start ||
         !end ||
         isNaN(new Date(start).getTime()) ||
         isNaN(new Date(end).getTime())
      ) {
         return false;
      }
      const now = new Date();
      const twoWeeksFromToday = normalizeDate(
         new Date(now.setDate(now.getDate() + 14))
      );
      const normalizedStart = normalizeDate(start);
      const normalizedEnd = normalizeDate(end);

      // Clone and calculate one month after normalized start date
      const oneMonthAfterStart = new Date(normalizedStart);
      oneMonthAfterStart.setMonth(oneMonthAfterStart.getMonth() + 1);

      let startDateError = "";
      let endDateError = "";

      if (normalizedStart < twoWeeksFromToday) {
         startDateError = "Start date must be at least 2 weeks from today.";
      }

      if (normalizedEnd < oneMonthAfterStart) {
         endDateError = "End date must be at least 1 month after start date.";
      }

      setErrors({ startDate: startDateError, endDate: endDateError });

      return !startDateError && !endDateError; // Return true if no errors
   };

   const handleStartDateChange = (date) => {
      setStartDate(date);
      validateDates(new Date(date), endDate);
   };

   const handleEndDateChange = (date) => {
      setEndDate(date);
      validateDates(startDate, new Date(date));
   };

   const hasValidInputs = () => {
      const hasValidDates = validateDates(startDate, endDate);
      if (!hasValidDates) return false;

      const isAnyResourceInvalid = resources.some((resource) => {
         const countAsNumber = Number(resource.count);
         return (
            !resource.role ||
            !resource.seniority ||
            !resource.worktime ||
            !resource.count ||
            countAsNumber <= 0
         );
      });

      return resources.length > 0 && !isAnyResourceInvalid;
   };

   useEffect(() => {
      const fetchResults = async () => {
         if (hasValidInputs()) {
            const newResults = await calculateResults(
               startDate,
               endDate,
               resources
            );
            setResults(newResults);
         } else if (!resources.length) setResults();
      };

      fetchResults();
   }, [startDate, endDate, resources]);

   useEffect(() => {
      if (results) {
         setBarChartData({
            countries: ["Portugal", "Spain", "France", "Germany"],
            costs: [
               results.totalCost,
               results.totalCost * 1.56,
               results.totalCost * 2.05,
               results.totalCost * 2.7,
            ],
         });
      }
   }, [results]);

   return (
      <Container>
         <InnerContainer>
            <MainWrapper>
               <InfoCard className="hireWiseHeader">
                  <div className="leftHeader">
                     <h2>HireWise</h2>
                     <p>
                        By <strong>Caixa Mágica Software</strong>
                     </p>
                  </div>
                  <div className="rightHeader">
                     <p>Share</p>
                     <ShareDropdown />
                  </div>
               </InfoCard>
               <CalculatorContainer>
                  <CalculatorContent
                     formProps={formProps}
                     isMobile={isMobile}
                     handleStartDateChange={handleStartDateChange}
                     handleEndDateChange={handleEndDateChange}
                     errors={errors}
                  />
               </CalculatorContainer>
               {resources.length > 0 &&
                  isMobile &&
                  resources.map((resource) => (
                     <MobileResourceCard
                        resource={resource}
                        deleteResource={deleteResource}
                        updateResource={updateResource}
                     >
                        {resource.role}
                     </MobileResourceCard>
                  ))}
            </MainWrapper>
            <InfoWrapper>
               <InfoCard>
                  <h3>Results</h3>
                  {results ? (
                     <div className="resultsContainer">
                        <div className="results">
                           <div className="resultLine">
                              <p>Billable hours</p>
                              <p>{results.billableHours}</p>
                           </div>
                           <div className="resultLine">
                              <p>Estimated cost</p>
                              <p>{formatCost(results.totalCost)}</p>
                           </div>
                           {/* <div className="resultLine">
                              <p>10% Desposit to guarantee your staff</p>
                              <p>{formatCost(results.totalCost * 0.1)}</p>
                           </div> */}
                           <div className="resultLine">
                              <p>Delivery</p>
                              <p>{results.deliveryTime}</p>
                           </div>
                        </div>
                        <div className="resultsButtons">
                           <Button
                              content="Schedule a call"
                              url="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2ZtOvz33ALy1KMOkIb3yrb4pwUREpURzrFrkG2TwbRJHLMFAvX_Kxr7hLMg8yFbSu3q9jNeiei"
                           />
                           <Button
                              type={ButtonTypes.SECONDARY}
                              variant={ButtonVariants.PRIMARY_COLOR}
                              Icon={DownloadSVG}
                              content="Download results in PDF"
                              onClick={() => setIsPopupOpen(true)}
                           />
                        </div>
                     </div>
                  ) : (
                     <p>
                        Please, provide some details so we can make the
                        calculations for you.
                     </p>
                  )}
               </InfoCard>
               <InfoCard className="compareResultsCard">
                  <h4>Compare results</h4>
                  {results ? (
                     <div className="compareResultsContainer">
                        <div className="barChartWrapper">
                           <BarChart data={barChartData} />
                        </div>
                        <div className="countriesCostsContainer">
                           {barChartData?.countries.map((country, i) => (
                              <ResultCountryTag
                                 key={i}
                                 country={country}
                                 formattedValue={formatCost(
                                    barChartData.costs[i]
                                 )}
                                 active={i === 0}
                              />
                           ))}
                        </div>
                     </div>
                  ) : (
                     <div className="compareResults noResults">
                        <div className="chartBar">
                           <ChartBarSVG />
                        </div>
                        <p>
                           Compare your results with nearby countries to see how
                           much you save by investing in nearshore in Portugal.
                        </p>
                     </div>
                  )}
               </InfoCard>
               {/* <InfoCard>
                  <ContactsInfo
                     sectionTitle="Reach out"
                     titleGap="14px"
                     textColor="var(--text-primary)"
                  />
               </InfoCard>
               <InfoCard>
                  <SocialMedia colorsInverted titleGap="14px" />
               </InfoCard> */}
            </InfoWrapper>
         </InnerContainer>
         <DownloadPopup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            projectInfo={projectInfo}
         />
      </Container>
   );
}
