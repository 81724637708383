import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarSVG from "../../images/symbols/calendar.svg";

const styleProps = {
   width: "100%",
   "& .MuiOutlinedInput-root": {
      height: "44px",
      flexDirection: "row-reverse",
      paddingRight: "8px",
      borderRadius: "8px",
      borderColor: "#d0d0d5",
      fontSize: "16px",
      "&:hover fieldset": {
         borderColor: "#d0d0d5",
      },
      "&.Mui-focused fieldset": {
         borderColor: "#d0d0d5",
      },
      "& .MuiInputAdornment-root": {
         marginRight: "8px",
         color: "#6e6e73",
      },
   },
   "& .MuiOutlinedInput-input": {
      height: "100%",
      padding: "0 12px",
      color: "var(--text-primary)",
      fontSize: "16px",
      fontFamily: "Red Hat Display, sans-serif",
      "&::placeholder": {
         color: "#898F9C",
         opacity: 1,
      },
   },
   "& .MuiInputLabel-root": {
      color: "#6e6e73",
      fontSize: "16px",
   },
   "& .Mui-disabled": {
      WebkitTextFillColor: "#6e6e73 !important",
      borderColor: "#d0d0d5",
   },
};

const DatePickerInput = ({
   label = "Select a date",
   value,
   onChange,
   minDate,
   maxDate,
   placeholder = "Pick a date...",
   ...props
}) => {
   return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
         <DatePicker
            value={value}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            format="dd/MM/yyyy"
            sx={styleProps}
            slots={{ openPickerIcon: CalendarSVG }}
            slotProps={{ textField: { placeholder } }}
            {...props}
         />
      </LocalizationProvider>
   );
};

export default DatePickerInput;
