import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
   WhatsappShareButton,
   TelegramShareButton,
   RedditShareButton,
   FacebookShareButton,
} from "react-share";
import WhatsappSVG from "../../images/symbols/whatsapp.svg";
import TelegramSVG from "../../images/symbols/telegram.svg";
import RedditSVG from "../../images/symbols/reddit.svg";
import FacebookSVG from "../../images/symbols/facebookOutline.svg";
import ShareSVG from "../../images/symbols/share.svg";

const DropdownContainer = styled.div`
   position: relative;
   display: inline-block;
`;

const DropdownButton = styled.button`
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${({ isOpen }) =>
      isOpen ? "#e0e0eb" : "var(--medium-bg)"};
   border: none;
   border-radius: 6px;
   cursor: pointer;
   width: 48px;
   height: 48px;

   &:hover {
      background-color: #e0e0eb;
   }
`;

const DropdownMenu = styled.div`
   position: absolute;
   top: 100%;
   right: 0;
   background-color: var(--light-bg);
   border-radius: 8px;
   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
   padding: 8px 0;
   width: 200px;
   z-index: 1000;
   display: ${({ isOpen }) => (isOpen ? "block" : "none")};

   button {
      width: 100%;
   }
`;

const MenuItem = styled.div`
   display: flex;
   align-items: center;
   gap: 12px;
   padding: 10px 16px;
   cursor: pointer;
   transition: background-color 0.2s ease;

   &:hover {
      background-color: var(--accent-primary-10);
   }
`;

const MenuItemText = styled.span`
   color: var(--text-primary);
   font-size: 16px;
   line-height: 26px;
`;

const ShareDropdown = () => {
   const [isOpen, setIsOpen] = useState(false);
   const dropdownRef = useRef(null);
   const [shareUrl, setShareUrl] = useState("");
   const shareText = "Cut Nearshore Costs with HireWise – Calculate for free";

   useEffect(() => {
      setShareUrl(window.location.href);
   }, []);

   const toggleDropdown = () => {
      setIsOpen(!isOpen);
   };

   // Close dropdown when clicking outside
   useEffect(() => {
      const handleClickOutside = (event) => {
         if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
         ) {
            setIsOpen(false);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   return (
      <DropdownContainer ref={dropdownRef}>
         <DropdownButton onClick={toggleDropdown} isOpen={isOpen}>
            <ShareSVG />
         </DropdownButton>
         <DropdownMenu isOpen={isOpen}>
            <WhatsappShareButton url={shareUrl} title={shareText}>
               <MenuItem>
                  <WhatsappSVG /> <MenuItemText>Whatsapp</MenuItemText>
               </MenuItem>
            </WhatsappShareButton>
            <TelegramShareButton url={shareUrl} title={shareText}>
               <MenuItem>
                  <TelegramSVG /> <MenuItemText>Telegram</MenuItemText>
               </MenuItem>
            </TelegramShareButton>
            <RedditShareButton url={shareUrl} title={shareText}>
               <MenuItem>
                  <RedditSVG /> <MenuItemText>Reddit</MenuItemText>
               </MenuItem>
            </RedditShareButton>
            <FacebookShareButton url={shareUrl} quote={shareText}>
               <MenuItem>
                  <FacebookSVG /> <MenuItemText>Facebook</MenuItemText>
               </MenuItem>
            </FacebookShareButton>
         </DropdownMenu>
      </DropdownContainer>
   );
};

export default ShareDropdown;
