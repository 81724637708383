import React, { useEffect, useRef } from "react";
import {
   Chart,
   BarController,
   BarElement,
   CategoryScale,
   LinearScale,
   Tooltip,
   Legend,
} from "chart.js";

Chart.register(
   BarController,
   BarElement,
   CategoryScale,
   LinearScale,
   Tooltip,
   Legend
);

export default function BarChart({ data }) {
   const chartRef = useRef(null);
   const chartInstanceRef = useRef(null);

   useEffect(() => {
      const ctx = chartRef.current.getContext("2d");

      // If a chart instance already exists, destroy it before creating a new one
      if (chartInstanceRef.current) {
         chartInstanceRef.current.destroy();
      }

      // Create a new chart instance and save it to the ref
      chartInstanceRef.current = new Chart(ctx, {
         type: "bar",
         data: {
            labels: data.countries,
            datasets: [
               {
                  label: "Cost",
                  data: data.costs,
                  backgroundColor: function (context) {
                     const { chart, dataIndex } = context;
                     const { ctx, chartArea } = chart;

                     if (!chartArea) return; // Return if the chart hasn't been rendered yet

                     // Create gradient for the entire chart area
                     const gradient = ctx.createLinearGradient(
                        0,
                        chartArea.top,
                        0,
                        chartArea.bottom
                     );

                     // Set colors based on the bar index
                     if (dataIndex === 0) {
                        gradient.addColorStop(0, "#2773F1");
                        gradient.addColorStop(1, "#2D65C0");
                     } else if (dataIndex === 1) {
                        gradient.addColorStop(0, "#2057B6");
                        gradient.addColorStop(1, "#224889");
                     } else if (dataIndex === 2) {
                        gradient.addColorStop(0, "#1A3C7F");
                        gradient.addColorStop(1, "#172C51");
                     } else if (dataIndex === 3) {
                        gradient.addColorStop(0, "#1C2F60");
                        gradient.addColorStop(1, "#151C2D");
                     }
                     return gradient;
                  },
                  borderRadius: 8, // For rounded corners
                  barPercentage: 0.8, // Adjust this value to control bar width
                  categoryPercentage: 1, // Adjust this to control space between bars
               },
            ],
         },
         options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
               y: {
                  beginAtZero: true,
                  grid: {
                     display: false, // Hide grid lines on the y-axis
                  },
                  ticks: {
                     callback: (value) => `${value / 1000}k`, // Format y-axis labels
                  },
               },
               x: {
                  grid: {
                     display: false, // Hide grid lines on the x-axis
                  },
               },
            },
            plugins: {
               legend: {
                  display: false, // Hide legend if not needed
               },
            },
         },
      });

      // Cleanup function to destroy the chart when the component unmounts
      return () => {
         if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
         }
      };
   }, [data]); // Add 'data' as a dependency to re-run the effect when 'data' changes

   return <canvas ref={chartRef}></canvas>;
}
