import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";

const customStyles = (isInsideTable) => ({
   container: (provided) => ({
      ...provided,
      flexGrow: "1",
      ...(isInsideTable && {
         margin: "0",
         width: "100%",
         border: "none",
      }),
   }),
   control: (provided, state) => ({
      ...provided,
      height: "44px",
      minHeight: "44px",
      padding: "0 12px",
      fontSize: "16px",
      border: state.isFocused
         ? "1px solid var(--accent-primary)"
         : "1px solid #d1d1d6",
      borderRadius: "8px",
      backgroundColor: "var(--light-bg-opacity-20)",
      color: "var(--text-primary)",
      cursor: "pointer",
      ...(isInsideTable && {
         backgroundColor: "transparent",
         border: "none !important",
         height: "26px",
         minHeight: "26px",
         padding: 0,
         boxShadow: "none",
         "& div": {
            padding: "0",
         },
      }),
   }),
   menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      backgroundColor: "var(--text-inverted)",
      overflow: "hidden",
      zIndex: 9999,
      ...(isInsideTable && {
         margin: 0,
         padding: 0,
      }),
   }),
   menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
   }),
   placeholder: (provided) => ({
      ...provided,
      color: "#898F9C",
      fontSize: "16px",
   }),
   option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
         ? "var(--accent-primary)"
         : "transparent",
      color: state.isSelected ? "var(--text-inverted)" : "var(--text-primary)",
      padding: "8px 10px",
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
         backgroundColor: state.isSelected
            ? "var(--accent-primary)"
            : "var(--accent-primary-05)",
         color: state.isSelected
            ? "var(--text-inverted)"
            : "var(--text-primary)",
      },
   }),
   singleValue: (provided) => ({
      ...provided,
      color: "var(--text-primary)",
      fontSize: "16px",
   }),
   valueContainer: (provided) => ({
      ...provided,
      height: isInsideTable ? "26px" : "40px",
   }),
   input: (provided) => ({
      ...provided,
      ...(isInsideTable && {
         margin: 0,
      }),
   }),
   dropdownIndicator: (provided) => ({
      ...provided,
      ...(isInsideTable && {
         margin: 0,
         padding: 0,
      }),
   }),
   indicatorsContainer: (provided, state) => ({
      ...provided,
      ...(isInsideTable && {
         height: "26px",
      }),
   }),
});

const SelectBox = ({
   options,
   value,
   onChange,
   clearOnSelect = false,
   placeholder = "Select",
   Icon,
   isInsideTable = false,
   classname,
}) => {
   const [selectedValue, setSelectedValue] = useState(value || null);
   const selectRef = useRef(null);
   const [menuPortalTarget, setMenuPortalTarget] = useState(null);

   useEffect(() => {
      setMenuPortalTarget(document.body);
   }, []);

   const CustomControl = (props) => (
      <components.Control {...props}>
         {Icon && <Icon />}
         {props.children}
      </components.Control>
   );

   const handleChange = (option) => {
      onChange(option);
      if (clearOnSelect) {
         setSelectedValue(null);
      } else {
         setSelectedValue(option);
      }

      if (selectRef.current) {
         selectRef.current.blur();
      }
   };
   return (
      <Select
         ref={selectRef}
         value={selectedValue}
         onChange={handleChange}
         options={options}
         styles={customStyles(isInsideTable)}
         isMulti={false}
         placeholder={placeholder}
         menuPortalTarget={menuPortalTarget}
         components={{
            Control: CustomControl,
            IndicatorSeparator: () => null,
         }}
         className={classname}
      />
   );
};

export default SelectBox;
